import { useLocation, useNavigate } from "react-router-dom";
import RobotMultiple from "../../components/RobotMultiple";

export const BulkEditPage = () => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const unitIds = params.getAll("unitIds");

  const navigate = useNavigate();
  const handleClick = () => {
    if (location.key === "default") {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return <RobotMultiple ids={unitIds} onClick={handleClick} />;
};

import {useEffect, useState} from 'react';
import {useMutation, useQuery, gql} from '@apollo/client';
import {
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useAuth} from './AuthContext';
import DialogOkCancel from './DialogOkCancel';
import LoadingLine from './LoadingLine';
import LogCategory from './LogCategory';
import S3File from './S3File';
import {prettifyHistoryLog} from '../utils/prettifyHistoryLog';
import {postSlack} from '../utils/slack';
import stateDecoder from '../utils/stateDecoder';
import toJSTString from '../utils/toJSTString';

const QUERY = gql`
  query GetRobot($id: ID!) {
    getRobot(id: $id) {
      id
      serial_number
      history {
        id
        operator_id
        state
        name
        location
        log
        log_category
        file_s3_key
        created_at
        deleted
      }
    }
  }
`;

const HIDE = gql`
  mutation HideLog($operator: String!, $id: ID!, $log: ID!) {
    hideLog(operator: $operator, id: $id, log: $log) {
      id
      serial_number
      history {
        id
        operator_id
        state
        name
        location
        log
        log_category
        file_s3_key
        created_at
        deleted
      }
    }
  }
`;

const HistoryLine = (history, showIds, onHideClick, onShowClick) => {
  const forceShow = !!showIds.find(i => i === history.id);
  const show = forceShow || history.deleted === 0;
  if (show) {
    return (
      <TableRow key={history.id}>
        <TableCell>{toJSTString(history.created_at)}</TableCell>
        <TableCell>{history.operator_id}</TableCell>
        <TableCell>{stateDecoder(history.state)}</TableCell>
        <TableCell>{history.name}</TableCell>
        <TableCell>{history.location}</TableCell>
        <TableCell>
          {
            /^https?:\/\/[\w!?/+\-_~=;.,*&@#$%()'[\]]+$/.test(history.log)
              ? <a href={history.log} target="_blank" rel="noopener noreferrer">{history.log}</a>
              : prettifyHistoryLog(history.log)
          }
        </TableCell>
        <TableCell><LogCategory disabled={forceShow} category={history.log_category}
                                onClick={() => onHideClick(history.id)}/></TableCell>
        <TableCell>{history.file_s3_key && history.file_s3_key.includes(',') ? history.file_s3_key.split(',').map((item, idx) => (
          <S3File key={idx} file_s3_key={item}/>
        )) : history.file_s3_key ? <S3File file_s3_key={history.file_s3_key}/> : null}</TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow key={history.id}>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>
          <IconButton size="small" onClick={() => onShowClick(history.id)}><MoreHorizIcon /></IconButton>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    );
  }
};

const RobotDetailHistory = ({id, isFileUploading}) => {
  const [hideLogId, setHideLogId] = useState('');
  const [openHide, setOpenHide] = useState(false);
  const [showIds, setShowIds] = useState([]);
  const {getEmail} = useAuth();
  const {loading, error, data, refetch} = useQuery(QUERY, {
    variables: {id: id}
  });
  const [hideLog] = useMutation(HIDE, {
    onCompleted: ({hideLog: robot}) => {
      const history = robot?.history?.[0];
      const target = `${history?.name ?? ''} / ${robot?.serial_number}`;
      const contents = `hide log: ${hideLogId}`;
      postSlack(getEmail(), `${target} ${contents}`);
    }
  });
  useEffect((prev) => {
    refetch();
  }, [isFileUploading, refetch])
  if (loading) return <LoadingLine />;
  if (error) return <p>Error :(</p>;
  const onCategoryClick = id => {
    if (!showIds.find(i => i === id)) {
      setHideLogId(id);
      setOpenHide(true);
    }
  };
  const onHideOk = () => {
    hideLog({
      variables: {
        operator: getEmail(),
        id: id,
        log: hideLogId
      }
    });
    setOpenHide(false);
  };
  const onShowClick = id => {
    setShowIds([...showIds, id]);
  };
  const contents = data.getRobot.history?.map(i => HistoryLine(i, showIds, onCategoryClick, onShowClick));
  return (
    <>
      {isFileUploading && <div>Uploading files... Please do not close the window. <LinearProgress hidden={isFileUploading} /></div>}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date time</TableCell>
              <TableCell>Operator</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Unit number</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Log</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>File</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contents}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogOkCancel
        open={openHide}
        title="Hide this log?"
        content="Are you sure?"
        onCancel={() => setOpenHide(false)}
        onOk={onHideOk}
      />
    </>
  );
};

export default RobotDetailHistory;

// vim: set expandtab shiftwidth=2:

import {
  Box,
  Button,
  Checkbox,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { UnitCard } from "./UnitCard";
import { useState } from "react";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ConstructUnitAction } from "../ConstructUnit/ConstructUnitAction";

export const UnitList = ({
  isLoading,
  units,
  totalItems,
  pageSize,
  pageNo,
}) => {
  const [editingUnitIdSet, setEditingUnitIdSet] = useState(new Set());
  const [isBulkEditChecked, setIsBulkEditChecked] = useState(false);

  const isIndeterminate =
    0 < editingUnitIdSet.size && editingUnitIdSet.size < units.length;

  const cannotBulkEdit = editingUnitIdSet.size < 2;

  const handleToggleAll = () => {
    setIsBulkEditChecked((checked) => !checked);
    if (editingUnitIdSet.size === units.length) {
      setEditingUnitIdSet(new Set());
    } else {
      setEditingUnitIdSet(new Set(units.map((unit) => unit.id)));
    }
  };

  const navigate = useNavigate();
  const handleBulkEdit = () => {
    const params = new URLSearchParams();
    editingUnitIdSet.forEach((unitId) => {
      params.append("unitIds", unitId);
    });
    navigate(`/units/bulk-edit?${params.toString()}`);
  };

  const handleUnitToggle = (unitId) => {
    const newEditingUnitIdSet = new Set(editingUnitIdSet);
    if (newEditingUnitIdSet.has(unitId)) {
      newEditingUnitIdSet.delete(unitId);
    } else {
      newEditingUnitIdSet.add(unitId);
      if (newEditingUnitIdSet.size === units.length) {
        setIsBulkEditChecked(true);
      }
    }
    setEditingUnitIdSet(newEditingUnitIdSet);
  };

  return (
    <Box sx={{ height: "100%", overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "space-between",
          rowGap: 2,
          columnGap: 2,
          px: 2,
          pb: 2,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              backgroundColor: "white",
              borderRadius: 2,
              pr: 2,
            }}
          >
            <Checkbox
              size="small"
              checked={isBulkEditChecked}
              indeterminate={isIndeterminate}
              onChange={handleToggleAll}
            />
            <Button
              variant="text"
              size="small"
              startIcon={<Edit />}
              disabled={cannotBulkEdit}
              onClick={handleBulkEdit}
            >
              Bulk Edit ({editingUnitIdSet.size})
            </Button>
          </Stack>
          <Typography variant="body1">
            {totalItems === 0
              ? "0 of 0"
              : `${pageSize * (pageNo - 1) + 1} - ${
                  pageSize * pageNo < totalItems
                    ? pageSize * pageNo
                    : totalItems
                } of ${totalItems}`}
          </Typography>
        </Stack>
        <ConstructUnitAction />
      </Box>
      <Box sx={{ height: "calc(100% - 52px)", overflowY: "auto" }}>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Grid container spacing={2} sx={{ px: 2, pb: 2 }}>
            {units.map((unit, index) => (
              <UnitCard
                key={unit.id}
                sequenceNumber={pageSize * (pageNo - 1) + index + 1}
                unit={unit}
                checked={editingUnitIdSet.has(unit.id)}
                onToggle={handleUnitToggle}
              />
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

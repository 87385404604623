import {useEffect, useState} from 'react';
import {useMutation, gql} from '@apollo/client';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import {useAuth} from './AuthContext';
import SlideDialog from './SlideDialog';
import {postSlack} from '../utils/slack';

const SET = gql`
  mutation UpdateUnitNumber($operator: String!, $id: ID!, $name: String!) {
    setName(operator: $operator, id: $id, name: $name) {
      id
      serial_number
      history {
        id
        state
        name
        location
        log
        created_at
      }
    }
  }
`;

const RobotDetailHeaderName = ({robot}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const {getEmail} = useAuth();
  const [setName] = useMutation(SET, {
    onCompleted: ({setName: robot}) => {
      const name = robot?.history?.[0].name ?? '';
      const target = `${name} / ${robot?.serial_number}`;
      postSlack(getEmail(), `${target}: name changed to ${name}`);
    }
  });
  useEffect(() => {
    if (open) {
      const name = robot.history?.[0].name ?? '';
      setValue(name);
    }
  }, [open, robot.history]);
  const onUpdate = () => {
    setName({variables: {operator: getEmail(), id: robot.id, name: value}});
    setOpen(false);
  };
  const error = value.length === 0;
  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={2} align="right">
          <Typography variant="body1" align="right">Unit number:</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body1">
            {robot.history?.[0].name ?? ''}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Button aria-label="Update Unit number" onClick={() => setOpen(true)}>Update</Button>
        </Grid>
      </Grid>
      <SlideDialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Update Unit number</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To update Unit number, Please enter here.
          </DialogContentText>
          <TextField
            fullWidth
            margin="normal"
            label="Unit number"
            value={value}
            onChange={e => setValue(e.target.value)}
            placeholder="Unit number"
            error={error}
            helperText={error ? 'Empty' : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button disabled={error} onClick={onUpdate}>Update</Button>
        </DialogActions>
      </SlideDialog>
    </>
  );
};

export default RobotDetailHeaderName;

// vim: set expandtab shiftwidth=2:

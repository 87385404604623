import {useState} from 'react';
import {useMutation, useQuery, gql} from '@apollo/client';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {useAuth} from './AuthContext';
import ButtonsAddDefine from './ButtonsAddDefine';
import DialogAdd from './DialogAdd';
import DialogDefine from './DialogDefine';
import DialogOkCancel from './DialogOkCancel';
import LoadingLine from './LoadingLine';
import {ignoreCaseComparator} from '../utils/comparator';
import {postSlack} from '../utils/slack';

const QUERY = gql`
  query GetRobotWithComponentList($id: ID!) {
    getRobot(id: $id) {
      id
      serial_number
      component {
        id
        description
        serial_number
      }
    }
    listComponents {
      id
      description
    }
  }
`;

const QUERYSERIAL = gql`
  query ListSerialPerComponent($id: ID!) {
    listSerialPerComponent(id: $id)
  }
`;

const DELETE = gql`
  mutation RemoveComponent($operator: String!, $id: ID!, $component: ID!, $serial: String!) {
    removeComponent(operator: $operator, id: $id, component: $component, serial: $serial) {
      id
      serial_number
      component {
        id
        description
        serial_number
      }
      history {
        id
        state
        name
        location
        log
        created_at
      }
    }
  }
`;

const ADD = gql`
  mutation AddComponent($operator: String!, $id: ID!, $component: ID!, $serial: String!) {
    addComponent(operator: $operator, id: $id, component: $component, serial: $serial) {
      id
      serial_number
      component {
        id
        description
        serial_number
      }
      history {
        id
        state
        name
        location
        log
        created_at
      }
    }
  }
`;

const DEFINE = gql`
  mutation DefineComponent($operator: String!, $description: String!) {
    defineComponent(operator: $operator, description: $description) {
      id
      description
    }
  }
`;

const makeMessage = (robot, message, list, info) => {
  const target = `${robot?.history?.[0].name ?? ''} / ${robot?.serial_number}`;
  const description = list?.find(i => i.id === info.id)?.description ?? '';
  const contents = `${message}: ${description}: ${info.serial}`;
  return `${target}: ${contents}`;
};

const RobotDetailComponent = ({id}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDefine, setOpenDefine] = useState(false);
  const [mutationInfo, setMutationInfo] = useState({});
  const {getEmail} = useAuth();
  const {loading, error, data} = useQuery(QUERY, {
    variables: {id: id}
  });
  const [deleteComponent] = useMutation(DELETE, {
    onCompleted: ({removeComponent: robot}) => {
      postSlack(getEmail(),
        makeMessage(
          robot,
          'remove component',
          data.listComponents,
          mutationInfo
        )
      );
    }
  });
  const [addComponent] = useMutation(ADD, {
    onCompleted: ({addComponent: robot}) => {
      postSlack(getEmail(),
        makeMessage(
          robot,
          'add component',
          data.listComponents,
          mutationInfo
        )
      );
    }
  });
  const [defineComponent] = useMutation(DEFINE, {
    refetchQueries: ['Query'],
    onCompleted: ({defineComponent: component}) => {
      postSlack(getEmail(), `define component: ${component?.description}`);
    }
  });
  if (loading) return <LoadingLine />;
  if (error) return <p>Error :(</p>;
  const onDeleteQuery = (componentId, serial) => {
    componentId = componentId.split('#')[0];
    setMutationInfo({id: componentId, serial: serial});
    setOpenDelete(true);
  };
  const onDeleteOk = () => {
    deleteComponent({
      variables: {
        operator: getEmail(),
        id: id,
        component: mutationInfo.id,
        serial: mutationInfo.serial
      }
    });
    setOpenDelete(false);
  };
  const onAdd = (componentId, serial) => {
    setMutationInfo({id: componentId, serial: serial});
    setTimeout(() => {
      addComponent({
        variables: {
          operator: getEmail(),
          id: id,
          component: componentId,
          serial: serial
        }
      });
    }, 0);
    setOpenAdd(false);
  };
  const onDefine = name => {
    defineComponent({
      variables: {
        operator: getEmail(),
        description: name
      }
    });
    setOpenDefine(false);
  };
  const contents = data.getRobot.component?.map(i =>
    <TableRow key={i.id}>
      <TableCell>{i.description}</TableCell>
      <TableCell>{i.serial_number}</TableCell>
      <TableCell>
        <Button onClick={e => onDeleteQuery(i.id, i.serial_number)}>
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
  const components = [...data.listComponents];
  components.sort((a, b) =>
    ignoreCaseComparator(a.description, b.description)
  );
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Component serial number</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contents}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonsAddDefine
        text="component"
        onAdd={() => setOpenAdd(true)}
        onDefine={() => setOpenDefine(true)}
      />
      <DialogAdd
        label1="Component"
        label2="Serial"
        open={openAdd}
        items={components}
        onCancel={() => setOpenAdd(false)}
        onOk={onAdd}
        queryDetails={QUERYSERIAL}
        queryName="listSerialPerComponent"
      />
      <DialogDefine
        open={openDefine}
        text="component"
        label="Component"
        onCancel={() => setOpenDefine(false)}
        onOk={onDefine}
      />
      <DialogOkCancel
        open={openDelete}
        title="Delete"
        content="Are you sure?"
        onCancel={() => setOpenDelete(false)}
        onOk={onDeleteOk}
      />
    </>
  );
};

export default RobotDetailComponent;

// vim: set expandtab shiftwidth=2:

import { Route, Routes } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import AuthContext from "./AuthContext";
import MainContents from "./MainContents";
import RobotTimeline from "./RobotTimeline";
import "../styles/App.css";
import { Layout } from "../features/Layout";
import { UnitListPage } from "../features/UnitList/UnitListPage";
import { BulkEditPage } from "../features/BulkEdit/BulkEditPage";
import { UnitDetailPage } from "../features/UnitDetail/UnitDetailPage";

const App = ({ signOut, user }) => (
  <div className="App">
    <AuthContext>
      <Routes>
        {/* Old routes */}
        <Route path="/card" element={<MainContents />} />

        {/* New routes */}
        <Route path="/" element={<Layout />}>
          {/* top page is here */}
          <Route index element={<UnitListPage />} />
          {/* For now we use the same component for the index and /units */}
          <Route path="/units" element={<UnitListPage />} />    
          <Route path="/units/bulk-edit" element={<BulkEditPage />} />
          <Route path="/units/:unitId" element={<UnitDetailPage />} />
        </Route>

        <Route path="/timeline" element={<RobotTimeline />} />
      </Routes>
    </AuthContext>
  </div>
);

export default withAuthenticator(App, {
  hideSignUp: true,
  socialProviders: ["google"],
});

// vim: set expandtab shiftwidth=2:

import { ChargingStation_ALL } from "../../constants/products/ChargingStation";
import {
  Lexx500_ALL,
  Lexx500_VERSIONS,
} from "../../constants/products/Lexx500";
import {
  LexxHub_ALL,
  LexxHub_VERSIONS,
} from "../../constants/products/LexxHub";
import {
  LexxTug_H_ALL,
  LexxTug_H_VERSIONS,
  LexxTug_L_ALL,
  LexxTug_L_VERSIONS,
} from "../../constants/products/LexxTug";
import { FilterDropdown } from "./FilterDropdown";

const filterItems = [
  {
    label: Lexx500_ALL.name,
    value: Lexx500_ALL.name,
    children: Lexx500_VERSIONS.map((version) => ({
      label: version.name,
      value: version.name,
    })),
  },
  {
    label: ChargingStation_ALL.name,
    value: ChargingStation_ALL.name,
  },
  {
    label: LexxTug_H_ALL.name,
    value: LexxTug_H_ALL.name,
    children: LexxTug_H_VERSIONS.map((version) => ({
      label: version.name,
      value: version.name,
    })),
  },
  {
    label: LexxTug_L_ALL.name,
    value: LexxTug_L_ALL.name,
    children: LexxTug_L_VERSIONS.map((version) => ({
      label: version.name,
      value: version.name,
    })),
  },
  {
    label: LexxHub_ALL.name,
    value: LexxHub_ALL.name,
    children: LexxHub_VERSIONS.map((version) => ({
      label: version.name,
      value: version.name,
    })),
  },
];

export const UnitListProductFilter = () => {
  return (
    <FilterDropdown
      label="Product"
      filterKey="products"
      filterItems={filterItems}
    />
  );
};

import stateDecoder from './stateDecoder';

export const prettifyHistoryLog = (history_log) => {
    // Currently the history log is stored as formatted text in the database
    // However, the states are represtented as integers, which is not user-friendly.
    // This function parses the history log and outputs a human-readable version, if applicable.

    const match = history_log.match(/modified state: (\d+) -> (\d+)/);
    if (Array.isArray(match) && match.length === 3) {
        const before = stateDecoder(match[1]);
        const after = stateDecoder(match[2]);
        return `modified state: ${before} -> ${after}`;
    } else {
        return history_log;
    }
}
import {useState} from 'react';
import {useMutation, gql} from '@apollo/client';
import {
  Button,
  Grid,
  Typography
} from '@mui/material';
import axios from 'axios';
import {useAuth} from './AuthContext';
import DialogLogAdd from './DialogLogAdd';
import {categoryDecoder} from '../utils/categoryDecoder';
import { prettifyHistoryLog } from '../utils/prettifyHistoryLog';
import {postSlack} from '../utils/slack';

const SET = gql`
  mutation AddLog($operator: String!, $id: ID!, $log: String!, $category: Int!) {
    setLog(operator: $operator, id: $id, log: $log, category: $category) {
      id
      serial_number
      history {
        id
        state
        name
        location
        log
        log_category
        created_at
      }
    }
  }
`;

const GENERATE_S3_UPLOAD_URL = gql`
  mutation GenerateS3UploadUrl($fileName: String!) {
    generateS3UploadUrl(fileName: $fileName) {
      s3Key
      signedUploadUrl
    }
  }
`;

const UPDATE_HISTORY_FILE_S3_KEY = gql`
  mutation AddFileS3KeyToHistory($operator: String!, $robotId: ID!, $historyId: ID!, $fileS3Key: String!) {
    addFileS3KeyToHistory(operator: $operator, id: $robotId, historyId: $historyId, fileS3Key: $fileS3Key) {
      id
    }
  }
`;

const RobotDetailHeaderLog = ({robot, setIsFileUploading}) => {
  const [open, setOpen] = useState(false);
  const [logInfo, setLogInfo] = useState({log: '', category: 0});
  const [uploadFiles, setUploadFiles] = useState([]);
  const [s3Info, setS3Info] = useState({url: '', key: []});
  const {getEmail} = useAuth();
  const [setLog] = useMutation(SET, {
    onCompleted: ({setLog: robot}) => {
      const history = robot?.history?.[0];
      const target = `${history?.name ?? ''} / ${robot?.serial_number}`;
      postSlack(getEmail(), `${target}: log add ${history?.log} (${categoryDecoder(history?.log_category)})`);
      setFileS3Key({
        variables: {
          operator: getEmail(),
          robotId: robot.id,
          historyId: history?.id,
          fileS3Key: s3Info.key.join(',')
        }
      });
    }
  });
  const [setFileS3Key] = useMutation(UPDATE_HISTORY_FILE_S3_KEY, {
    onCompleted: _ => {
      setIsFileUploading(false);
    }
  });
  const [generateS3UploadUrl] = useMutation(GENERATE_S3_UPLOAD_URL, {
    onCompleted: result => {
      setS3Info({
        url: result.generateS3UploadUrl.signedUploadUrl,
        key: [...s3Info.key, result.generateS3UploadUrl.s3Key]
      });
      const file = uploadFiles?.[0];
      if (file) {
        setIsFileUploading(true);
        axios.put(result.generateS3UploadUrl.signedUploadUrl, file).then(
          _ => {
            postSlack(getEmail(), `file upload to ${result.generateS3UploadUrl.s3Key}`);
            const remainFiles = uploadFiles.slice(1);
            setUploadFiles(remainFiles);
            const nextFile = remainFiles?.[0];
            if (nextFile)
              setTimeout(() => {
                generateS3UploadUrl({variables: {fileName: nextFile.name}});
              }, 0);
            else
              setTimeout(() => {
                setLog({variables: {operator: getEmail(), id: robot.id, log: logInfo.log, category: logInfo.category}});
              }, 0);
          }
        ).catch(
          err => {
            setIsFileUploading(false);
            console.log(err);
          }
        );
      }
    }
  });
  const onClick = () => {
    setLogInfo({log: '', category: 0});
    setUploadFiles([]);
    setOpen(true);
    setS3Info({url: '', key: []});
  };
  const onLogAdd = ({log, category, files}) => {
    setLogInfo({log: log, category: category});
    setUploadFiles(files);
    const file = files?.[0];
    if (file){
      setTimeout(() => {
        generateS3UploadUrl({variables: {fileName: file.name}});
      }, 0);
    } else {
      setLog({variables: {operator: getEmail(), id: robot.id, log: log, category: category}});
    }
    setOpen(false);
  };
  const log = robot.history?.[0].log ?? '';
  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={2} align="right">
          <Typography variant="body1" align="right">Log:</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body1">
            {
              /^https?:\/\/[\w!?/+\-_~=;.,*&@#$%()'[\]]+$/.test(log)
                ? <a href={log} target="_blank" rel="noopener noreferrer">{log}</a>
                : prettifyHistoryLog(log)
            }
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Button aria-label="Add Log" onClick={onClick}>Add</Button>
        </Grid>
      </Grid>
      <DialogLogAdd
        open={open}
        onCancel={() => setOpen(false)}
        onOk={onLogAdd}
      />
    </>
  );
};

export default RobotDetailHeaderLog;

// vim: set expandtab shiftwidth=2:

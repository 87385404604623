import { FilterDropdown } from "./FilterDropdown";
import { useTagListQuery } from "./api/getTagList";

export const UnitListTagFilter = () => {
  const { data } = useTagListQuery();

  const filterItems = (data?.listTags ?? []).map((tag) => ({
    label: tag.description,
    value: tag.description,
  }));

  return (
    <FilterDropdown label="Tag" filterKey="tags" filterItems={filterItems} />
  );
};

import { useLocationListQuery } from "./api/getLocationList";
import { FilterDropdown } from "./FilterDropdown";

export const UnitListLocationFilter = () => {
  const { data } = useLocationListQuery();

  const filterItems = (data?.locationList ?? []).map((location) => ({
    label: location,
    value: location,
  }));

  return (
    <FilterDropdown
      label="Location"
      filterKey="locations"
      filterItems={filterItems}
    />
  );
};

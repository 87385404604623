import { Box, Card, Checkbox, Grid, Stack, Typography } from "@mui/material";
import { stateColor } from "../../utils/color";
import toJSTString from "../../utils/toJSTString";
import stateDecoder from "../../utils/stateDecoder";
import { prettifyHistoryLog } from '../../utils/prettifyHistoryLog';
import { useNavigate } from "react-router-dom";

export const UnitCard = ({ sequenceNumber, unit, checked, onToggle }) => {
  const handleCheckChange = () => {
    onToggle(unit.id);
  };

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/units/${unit.id}`);
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card>
        <Stack direction="row" alignItems="flex-start">
          <Checkbox
            size="small"
            checked={checked}
            onChange={handleCheckChange}
            inputProps={{ "aria-label": unit.latestHistory.unitNumber }}
          />
          <Box
            sx={{
              width: "calc(100% - 38px)",
              padding: "8px 8px 8px 0",
              cursor: "pointer",
            }}
            onClick={handleClick}
          >
            <Grid container>
              <Grid item xs={5}>
                <Typography noWrap>
                  {unit.latestHistory.unitNumber ?? "[No name]"}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1" sx={{ textAlign: "right" }}>
                  {unit.latestHistory.location}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={5}>
                <Typography variant="caption">
                  {toJSTString(unit.latestHistory.createdAt)}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  component="div"
                  variant="caption"
                  sx={{ textAlign: "right" }}
                >
                  {unit.serialNumber}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" noWrap>
              {prettifyHistoryLog(unit.latestHistory.log)}
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingLeft: "38px",
            paddingRight: "8px",
            background: stateColor(unit.latestHistory.state),
          }}
        >
          <Typography variant="caption">
            {stateDecoder(unit.latestHistory.state)}
          </Typography>
          <Typography variant="caption">
            {sequenceNumber}
          </Typography>
        </Stack>
      </Card>
    </Grid>
  );
};

import { gql, useMutation } from "@apollo/client";

const CONSTRUCT_UNITS = gql`
  mutation ConstructUnits($operator: String!, $count: Int!, $name: String) {
    constructRobots(operator: $operator, count: $count, name: $name) {
      id
      serial_number
    }
  }
`;

export const useConstructUnitsMutation = (options) => {
  return useMutation(CONSTRUCT_UNITS, options);
};

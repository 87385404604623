import { Stack } from "@mui/material";
import KarteBar from "../components/KarteBar";
import { Outlet } from "react-router-dom";

export const Layout = () => {
  return (
    <Stack sx={{ height: "100dvh" }}>
      <KarteBar />
      <Outlet />
    </Stack>
  );
};

import { Chip, Stack } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useLocation, useNavigate } from "react-router-dom";
import { Sort } from "@mui/icons-material";

export const DEFAULT_SORT_KEY = "UnitNumber";

export const DEFAULT_SORT_DIRECTION = "Asc";

export const UnitListSort = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sortKey = params.get("sortKey") ?? "";
  const sortDirection = params.get("sortDirection") ?? "";

  const icon =
    sortDirection === "Asc" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;

  const navigate = useNavigate();
  const handleSortChange = (newSortKey) => {
    if (newSortKey === sortKey) {
      params.set("sortDirection", sortDirection === "Asc" ? "Desc" : "Asc");
    } else {
      params.set("sortKey", newSortKey);
      params.set("sortDirection", "Asc");
    }

    navigate(`/?${params.toString()}`);
  };

  return (
    <Stack
      aria-label="Unit List Sort"
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ height: '40px', backgroundColor: "white", borderRadius: 2, px: 2 }}
    >
      <Sort color="primary" />
      <Chip
        label="Unit Number"
        variant="outlined"
        size="small"
        color={sortKey === "UnitNumber" ? "primary" : "default"}
        icon={sortKey === "UnitNumber" ? icon : null}
        onClick={() => handleSortChange("UnitNumber")}
      />
      <Chip
        label="State"
        variant="outlined"
        size="small"
        color={sortKey === "State" ? "primary" : "default"}
        icon={sortKey === "State" ? icon : null}
        onClick={() => handleSortChange("State")}
      />
    </Stack>
  );
};

import {
  Badge,
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import { FilterList, HighlightOff } from "@mui/icons-material";

const FilterItem = ({ label, value, checked, onChange, indent }) => {
  return (
    <MenuItem sx={{ py: 0 }}>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            size="small"
            value={value}
            checked={checked}
            onChange={onChange}
            inputProps={{ "aria-label": label }}
          />
        }
        sx={{
          display: "block",
          width: "100%",
          mr: 0,
          pl: indent ? 3 : 0,
        }}
      />
    </MenuItem>
  );
};

export const FilterDropdown = ({ label, filterKey, filterItems }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const filterValues = params.getAll(filterKey) ?? [];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const handleFilterClear = (event) => {
    event.stopPropagation();
    params.delete(filterKey);
    navigate(`/?${params.toString()}`);
  };

  const navigate = useNavigate();
  const handleCheckChange = (event) => {
    params.delete("pageNo");

    const value = event.target.value;
    const newValueSet = new Set(filterValues);
    if (newValueSet.has(value)) {
      params.delete(filterKey, value);
    } else {
      params.append(filterKey, value);
    }

    navigate(`/?${params.toString()}`);
  };

  return (
    <>
      <Button
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          height: 40,
          px: 2,
          py: 1,
        }}
        size="small"
        variant="text"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        startIcon={
          <Badge
            badgeContent={filterValues.length}
            color="primary"
            variant="dot"
          >
            <FilterList />
          </Badge>
        }
        endIcon={
          filterValues.length > 0 ? (
            <HighlightOff
              onClick={handleFilterClear}
              sx={{ fontSize: "24px !important" }}
            />
          ) : null
        }
        onClick={handleClick}
      >
        {label}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {filterItems.map((item) => {
          return (
            <div key={item.value}>
              <FilterItem
                label={item.label}
                value={item.value}
                checked={filterValues.includes(item.value)}
                onChange={handleCheckChange}
                sx={{ display: "block", width: "100%", mr: 0 }}
              />
              {item.children &&
                item.children.map((child) => (
                  <FilterItem
                    key={child.value}
                    label={child.label}
                    value={child.value}
                    checked={filterValues.includes(child.value)}
                    onChange={handleCheckChange}
                    indent
                  />
                ))}
            </div>
          );
        })}
      </Menu>
    </>
  );
};

import { Box, Stack } from "@mui/material";
import { useUnitListQuery } from "./api/getUnitList";
import { useLocation } from "react-router-dom";
import { UnitList } from "./UnitList";
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  UnitListPagination,
} from "./UnitListPagination";
import {
  DEFAULT_SORT_DIRECTION,
  DEFAULT_SORT_KEY,
  UnitListSort,
} from "./UnitListSort";
import { UnitListSearch } from "./UnitListSearch";
import { UnitListFilters } from "./UnitListFilters";

export const UnitListPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const pageNo = parseInt(params.get("pageNo") ?? `${DEFAULT_PAGE_NO}`, 10);
  const pageSize = parseInt(
    params.get("pageSize") ?? `${DEFAULT_PAGE_SIZE}`,
    10
  );

  const sortKey = params.get("sortKey") ?? DEFAULT_SORT_KEY;
  const sortDirection = params.get("sortDirection") ?? DEFAULT_SORT_DIRECTION;

  const keyword = params.get("keyword");

  const products = params.getAll("products") ?? [];
  const states = (params.getAll("states") ?? []).map(Number);
  const locations = params.getAll("locations") ?? [];
  const tags = params.getAll("tags") ?? [];

  const { loading, data } = useUnitListQuery({
    pagination: { pageNo, pageSize },
    sort: { sortKey, sortDirection },
    search: { keyword },
    filter: { products, states, locations, tags },
  });

  return (
    <Stack sx={{ height: "calc(100% - 64px)", backgroundColor: "#efefef" }}>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row wrap",
          rowGap: 2,
          columnGap: 2,
          p: 2,
        }}
      >
        <UnitListSearch />
        <UnitListFilters />
        <UnitListSort />
      </Box>

      <UnitList
        isLoading={loading}
        units={data?.unitList?.items ?? []}
        totalItems={data?.unitList?.pageInfo.totalItems ?? 0}
        pageSize={pageSize}
        pageNo={pageNo}
      />

      <Stack
        direction="row"
        justifyContent="center"
        sx={{ zIndex: 1, px: 2, py: 1 }}
      >
        <UnitListPagination
          totalPages={data?.unitList?.pageInfo.totalPages ?? 0}
        />
      </Stack>
    </Stack>
  );
};

export const state = [
  'Constructed',
  'In manufacturing',
  'Finished manufacturing',
  'In the warehouse',
  'Shipped',
  'In operation',
  'In trouble',
  'In repair',
  'Disposed',
  'Unknown',
];

const stateDecoder = number => {
  if (number >= state.length)
    number = state.length - 1;
  return state[number];
};

export default stateDecoder;

// vim: set expandtab shiftwidth=2:

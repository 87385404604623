import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { Clear, Search } from "@mui/icons-material";

// debounce function
function debounce(func, wait = 300) {
  let timeout;
  function debounced(...args) {
    const later = () => {
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

export const UnitListSearch = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [keyword, setKeyword] = useState(params.get("keyword") ?? "");

  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce((keyword) => {
      const newParams = new URLSearchParams(window.location.search);
      newParams.delete("pageNo");

      if (keyword === "") {
        newParams.delete("keyword");
      } else {
        newParams.set("keyword", keyword);
      }

      navigate(`/?${newParams.toString()}`);
    }),
    []
  );

  const handleChange = (e) => {
    setKeyword(e.target.value);
    debouncedChangeHandler(e.target.value);
  };

  const handleClear = () => {
    setKeyword("");
    params.delete("keyword");
    navigate(`/?${params.toString()}`);
  };

  return (
    <OutlinedInput
      placeholder="Search by Unit Number, Log, Component, Software"
      size="small"
      sx={{
        backgroundColor: "white",
        minWidth: 440,
        borderRadius: 2,
        fieldset: {
          borderRadius: 2,
        },
        "&:not(:focus-within)": {
          fieldset: {
            border: "none",
          },
        },
      }}
      startAdornment={
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      }
      endAdornment={
        keyword && (
          <InputAdornment position="end">
            <IconButton size="small" onClick={handleClear}>
              <Clear />
            </IconButton>
          </InputAdornment>
        )
      }
      value={keyword}
      onChange={handleChange}
    />
  );
};

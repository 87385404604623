import { Button } from "@mui/material";
import { useState } from "react";
import DialogConstruct from "../../components/DialogConstruct";
import { useConstructUnitsMutation } from "./api/constructUnits";
import { useAuth } from "../../components/AuthContext";
import { postSlack } from "../../utils/slack";
import { Add } from "@mui/icons-material";

export const ConstructUnitAction = () => {
  const [open, setOpen] = useState(false);

  const { getEmail } = useAuth();
  const operator = getEmail();

  const [constructUnits] = useConstructUnitsMutation({
    refetchQueries: ["GetUnitList"],
    onCompleted: ({ constructRobots: robots }) => {
      const contents = robots.map((i) => i.serial_number).join("\n");
      postSlack(operator, `constructed\n${contents}`);
    },
  });

  const handleOk = (count, name) => {
    constructUnits({ variables: { operator, count, name } });
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => setOpen(true)}
      >
        Construct Unit
      </Button>
      <DialogConstruct
        open={open}
        onCancel={() => setOpen(false)}
        onOk={handleOk}
      />
    </>
  );
};

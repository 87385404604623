import { Stack } from "@mui/material";
import { UnitListStateFilter } from "./UnitListStateFilter";
import { UnitListProductFilter } from "./UnitListProductFilter";
import { UnitListLocationFilter } from "./UnitListLocationFilter";
import { UnitListTagFilter } from "./UnitListTagFilter";

export const UnitListFilters = () => {
  return (
    <Stack
      aria-label="Unit List Filters"
      direction="row"
      spacing={2}
      alignItems="center"
    >
      <UnitListProductFilter />
      <UnitListStateFilter />
      <UnitListLocationFilter />
      <UnitListTagFilter />
    </Stack>
  );
};

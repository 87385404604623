import {useQuery, gql} from '@apollo/client';
import {Grid, Typography} from '@mui/material';
import LoadingLine from './LoadingLine';
import RobotDetailHeaderLocation from './RobotDetailHeaderLocation';
import RobotDetailHeaderLog from './RobotDetailHeaderLog';
import RobotDetailHeaderName from './RobotDetailHeaderName';
import RobotDetailHeaderState from './RobotDetailHeaderState';

const QUERY = gql`
  query GetRobot($id: ID!) {
    getRobot(id: $id) {
      id
      serial_number
      history {
        id
        state
        name
        location
        log
        log_category
        created_at
      }
    }
  }
`;

const RobotDetailHeader = ({id, setIsFileUploading}) => {
  const {loading, error, data} = useQuery(QUERY, {
    variables: {id: id}
  });
  if (loading) return <LoadingLine />;
  if (error) return <p>Error :(</p>;
  return (
    <Grid aria-label="Detail Header" container spacing={1}>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body1" align="right">
              Serial number:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body1">
              {data.getRobot?.serial_number}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <RobotDetailHeaderState robot={data.getRobot} />
      </Grid>
      <Grid item xs={12}>
        <RobotDetailHeaderName robot={data.getRobot} />
      </Grid>
      <Grid item xs={12}>
        <RobotDetailHeaderLocation robot={data.getRobot} />
      </Grid>
      <Grid item xs={12}>
        <RobotDetailHeaderLog robot={data.getRobot} setIsFileUploading={setIsFileUploading} />
      </Grid>
    </Grid>
  );
};

export default RobotDetailHeader;

// vim: set expandtab shiftwidth=2:

import { gql, useQuery } from "@apollo/client";

const QUERY = gql`
  query GetTagList {
    listTags {
      id
      description
    }
  }
`;

export const useTagListQuery = () => {
  return useQuery(QUERY, {
    fetchPolicy: "cache-and-network",
  });
};

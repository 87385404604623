import { useLocation, useNavigate, useParams } from "react-router-dom";
import RobotDetail from "../../components/RobotDetail";

export const UnitDetailPage = () => {
  const { unitId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    // https://api.reactrouter.com/v7/interfaces/react_router.Location.html#key
    if (location.key === "default") {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return <RobotDetail id={unitId} onClick={handleClick} />;
};

import { gql, useQuery } from "@apollo/client";

const QUERY = gql`
  query GetUnitList(
    $pagination: OffsetPageInput!
    $sort: UnitCollectionSortInput!
    $search: UnitCollectionSearchInput!
    $filter: UnitCollectionFilterInput!
  ) {
    unitList(
      pagination: $pagination
      sort: $sort
      search: $search
      filter: $filter
    ) {
      pageInfo {
        pageNo
        pageSize
        totalItems
        totalPages
      }
      items {
        id
        serialNumber
        latestHistory {
          unitNumber
          location
          state
          log
          createdAt
        }
      }
    }
  }
`;

export const useUnitListQuery = ({ pagination, sort, search, filter }) => {
  return useQuery(QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      pagination: {
        pageNo: 1,
        pageSize: 25,
        ...pagination,
      },
      sort: {
        sortKey: "UnitNumber",
        sortDirection: "Asc",
        ...sort,
      },
      search,
      filter
    },
  });
};

import {
  FormControl,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  Stack,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";

const PAGE_SIZE_LIST = [10, 25, 50, 100];

export const DEFAULT_PAGE_NO = 1;

export const DEFAULT_PAGE_SIZE = 25;

export const UnitListPagination = ({ totalPages }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const pageNo = parseInt(params.get("pageNo"), 10) || DEFAULT_PAGE_NO;
  const pageSize = parseInt(params.get("pageSize"), 10) || DEFAULT_PAGE_SIZE;

  const navigate = useNavigate();
  const handlePageSizeChange = (e) => {
    const pageSize = e.target.value;
    params.set("pageSize", pageSize);
    params.set("pageNo", DEFAULT_PAGE_NO);
    navigate(`/?${params.toString()}`);
  };

  return (
    <Stack
      aria-label="Unit List Pagination"
      direction="row"
      alignItems="center"
      spacing={2}
    >
      <Pagination
        shape="rounded"
        variant="text"
        color="primary"
        showFirstButton
        showLastButton
        count={totalPages}
        page={pageNo}
        sx={{ alignContent: "center" }}
        renderItem={(item) => {
          params.set("pageNo", item.page);

          if (pageSize !== DEFAULT_PAGE_SIZE) {
            params.set("pageSize", pageSize);
          }

          return (
            <PaginationItem
              component={Link}
              to={`/?${params.toString()}`}
              {...item}
            />
          );
        }}
      />
      <FormControl variant="standard">
        <Select
          aria-label="Page Size"
          value={pageSize}
          onChange={handlePageSizeChange}
        >
          {PAGE_SIZE_LIST.map((pageSize) => (
            <MenuItem key={pageSize} value={pageSize}>
              {pageSize}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

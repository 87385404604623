import { gql, useQuery } from "@apollo/client";

const QUERY = gql`
  query GetLocationList {
    locationList
  }
`;

export const useLocationListQuery = () => {
  return useQuery(QUERY, {
    fetchPolicy: "cache-and-network",
  });
};

import stateDecoder, { state } from "../../utils/stateDecoder";
import { FilterDropdown } from "./FilterDropdown";

const filterItems = state.map((s, i) => ({
  label: stateDecoder(i),
  value: i.toString(),
}));

export const UnitListStateFilter = () => {
  return (
    <FilterDropdown
      label="State"
      filterKey="states"
      filterItems={filterItems}
    />
  );
};
